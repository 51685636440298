import React from "react";
import axios, { AxiosInstance, AxiosResponse } from "axios";
// import {
//   DailyPayoutProps,
//   MultiplePayoutProps,
// } from "../@types/DRO/DailyPayoutResponse";
import { LoginRespone } from "../@types/DRO/LoginResponse";
import { DailyPayoutPropsArray } from "../@types/DRO/DailyPayoutResponse";

export class ZyppUploadApi {
  public static api = new ZyppUploadApi();
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_ZYPP_UPLOAD_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  onlineUserAuthentication(
    username: string,
    password: string
  ): Promise<AxiosResponse<LoginRespone>> {
    return axios.post<LoginRespone>(
      "https://manager.bcykal.com/mobycy/payout-manager/login",
      {
        username,
        password,
      }
    );
  }
  getToken() {
    return localStorage.getItem("token");
  }

  dailyPayoutList(
    page: number = 0,
    pageSize: number = 7,
    sortBy: string = "",
    orderBy: string = "asc"
  ): Promise<DailyPayoutPropsArray[]> {
    return this.instance
      .get<DailyPayoutPropsArray[]>(
        "https://manager.bcykal.com/mobycy/payout-manager/pilot/daily/payout/files",
        {
          params: {
            page,
            pageSize,
            sortBy,
            orderBy,
          },
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }
  multipleDailyPayoutList(
    page: number = 0,
    pageSize: number = 7,
    sortBy: string = "",
    orderBy: string = "asc"
  ): Promise<DailyPayoutPropsArray[]> {
    return this.instance
      .get<DailyPayoutPropsArray[]>(
        "https://manager.bcykal.com/mobycy/payout-manager/pilot/multiClient/daily/payout/files",
        {
          params: {
            page,
            pageSize,
            sortBy,
            orderBy,
          },

          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((e) => {
        return e.data;
      });
  }

  payoutFileRefresh(type: number): Promise<void> {
    return this.instance
      .post(
        `https://manager.bcykal.com/mobycy/payout-manager/pilot/payout/file/refresh?type=${type}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Payout file refresh successful.");
        } else {
          throw new Error("Failed to refresh payout file.");
        }
      })
      .catch((error) => {
        console.error("Error refreshing payout file:", error);
        throw error;
      });
  }
}
