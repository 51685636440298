import React from "react";
import { Routes } from "../states/states";
import AppHeader from "../components/Layouts/AppHeader/AppHeader";
import DailyPayout from "../components/UI/DailyPayout";
import Homepage from "../views/home";
import Sidebar from "../components/Layouts/Sidebar/AppSidebar";
import MultiClientPayout from "../components/UI/MulticlientPayout";
import PayoutRelease from "../components/UI/PayoutRelease";
import Login from "../components/UI/Login";
import { Navigate } from "react-router";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = localStorage.getItem("token");
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return children;
};
const CommonRoutes: Array<Routes> = [
  // {
  //   path: "/",
  //   element: <Homepage />,
  // },
  {
    path: "/header",
    element: <AppHeader />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dailyPayout",
    element: (
      <RequireAuth>
        <DailyPayout />
      </RequireAuth>
    ),
  },
  {
    path: "/multiclientPayout",
    element: (
      <RequireAuth>
        <MultiClientPayout />
      </RequireAuth>
    ),
  },
  {
    path: "/payoutRelease",
    element: <PayoutRelease />,
  },
  {
    path: "/sideBar",
    element: <Sidebar />,
  },
];
export const Router: Array<Routes> = [
  ...CommonRoutes,
  /**
   *@description
   * Url to Embeded in Iframe
   *
   */
  // {
  //   path: "", // test route
  //   element: <Header />,
  //   children: [
  //     ...CommonRoutes,
  //     {
  //       path: "*", // test route
  //       element: <div>NOT FOUND</div>,
  //     },
  //   ],
  // },
];
